import store from '@/store';
// contants
import { StatusConstants } from '@/constants/status';
//mixins
import modal from '@/mixins/plugin/modal';

export default {
  data: function() {
    return {
      isDisable: false,
      urlCsv: '',
      statusTimer: null,
      downloadCSVModal: false,
    }
  },
  mixins: [modal],
  methods: {
    downloadCsv(list, filename) {
      this.convertToCsv(list, filename);
    },
    convertToCsv(list, filename) {
      try {
        const unit8Array = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blob = new Blob([unit8Array, list], { type: 'text/csv;charset=Shift_JIS' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("charset", "Shift_JIS");
          link.setAttribute(
            "download",
            filename
          );
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        store.dispatch('ADD_ALERTS', {
          message: err,
          type: 'error',
        });
      }
    },
    downloadUrlCsv(url) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.setAttribute("href", url);
      a.style.visibility = 'hidden';
      a.click();
      document.body.removeChild(a);
    },
    saveIdRequestExport(id) {
      localStorage.setItem(this.storedField, id);
    },
    removeIdRequestExport() {
      localStorage.removeItem(this.storedField);
    },
    getIdRequestExport() {
      return this.storedField && localStorage.getItem(this.storedField);
    },
    cancelDownloadCsv() {
      this.closeModal('downloadCSVModal');
      this.isDisable = false;
      this.removeIdRequestExport();
    },
    async acceptDownloadCsv() {
      const response = await this.$store.dispatch('common/getStatusExportCSV', this.getIdRequestExport());
      if (response) {
        this.downloadUrlCsv(this.urlCsv);
      } else {
        this.removeIdRequestExport();
      }
    },
    async checkExportCsvStatus(id) {
      this.statusTimer = setTimeout(async () => {
        const response = await this.$store.dispatch('common/getStatusExportCSV', id);
        if (response) {
          switch(response.status) {
            case StatusConstants.exportCsvStatus.waiting:
              this.checkExportCsvStatus(id);
              break;
            case StatusConstants.exportCsvStatus.success:
              this.urlCsv = response.url;
              this.showModal('downloadCSVModal')
              break;
            case StatusConstants.exportCsvStatus.cancel:
              this.$message.showMessageError(response.error);
              this.closeModal('downloadCSVModal');
              this.removeIdRequestExport();
              break;
            default:
              break;
          }
        } else {
          this.removeIdRequestExport();
        }
      }, 5000)
    },
  },
  mounted() {
    const idRequestExportCustomer = this.getIdRequestExport();
    if (idRequestExportCustomer) {
      this.checkExportCsvStatus(idRequestExportCustomer)
    }
  },
  beforeDestroy() {
    clearTimeout(this.statusTimer);
  },
};
